import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./App.css";
import "./styles/animations.css";
import Home from "./pages/home";
import InfoCard from "./pages/info_card";
import Contact_form from "./pages/contact_form";
import Links from "./pages/links";
import particlesOptions from "./particles.json";

function App() {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  return (
    <div>
      <Particles options={particlesOptions} init={particlesInit} />
      <section>
        <div class="progress"></div>
        <div class="cube-wrap">
          <div class="cube">
            <div class="side top"></div>
            <div class="side bottom"></div>
            <div class="side front"></div>
            <div class="side back"></div>
            <div class="side left"></div>
            <div class="side right"></div>
          </div>
        </div>
      </section>
      <section>
        <div class="progress"></div>
        <div class="cube-wrap-two">
          <div class="cube-two">
            <div class="side top"></div>
            <div class="side bottom"></div>
            <div class="side front"></div>
            <div class="side back"></div>
            <div class="side left"></div>
            <div class="side right"></div>
          </div>
        </div>
      </section>
      <section class="mini-wrapper">
        <div class="container">
          <div class="content-wrapper box1">
            <Home />
          </div>
          <div class="content-wrapper box2">
            <InfoCard />
          </div>
          <div class="content-wrapper box3">
            <Contact_form />
          </div>
          <div class="content-wrapper box4">
            <Links />
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;

import React, { useCallback } from "react";
import { HiArrowCircleRight } from "react-icons/hi";
import { HiArrowCircleLeft } from "react-icons/hi";
import "../App.css";
import "../styles/animations.css";

function InfoCard() {

  return (
    <div class="container-wrapper content-wrapper" id="About">
      <a className="App-link floating card-hover-animation remove" href="#Home">
        <HiArrowCircleLeft size={50} />
      </a>

      <div class="flip-card-front floating">
        <p class="card-hover-animation">
          A passionate developer with a love for learning.<br/><br/>Constantly seeking out new opportunities to expand knowledge and skills through work and
          personal projects.
        </p>
      </div>
      <h3 class="profile-name floating card-hover-animation">J. Pilotte</h3>

      <a className="App-link floating card-hover-animation remove" href="#Contact">
        <HiArrowCircleRight size={50} />
      </a>
    </div>
  );
}

export default InfoCard;

import React, { useCallback } from "react";
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiFillCodepenSquare } from "react-icons/ai";
import { HiArrowCircleLeft } from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";
import "../App.css";
import "../styles/animations.css";

function Links() {
  return (
    <div class="container-wrapper icons" id="Links">
      <a className="App-link floating card-hover-animation remove" href="#Contact">
        <HiArrowCircleLeft size={70} />
      </a>
      <a
        className="App-link floating card-hover-animation"
        href="https://github.com/Dot-kpp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithubSquare size={70} />
      </a>
      <a
        className="App-link floating card-hover-animation"
        href="https://linkedin.com/in/jeremie-pilotte-791192145"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin size={70} />
      </a>
      <a
        className="App-link floating card-hover-animation"
        href="https://codepen.io/dot-kpp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiFillCodepenSquare size={70} />
      </a>
      <a className="App-link floating card-hover-animation" href="#Home">
        <AiFillHome size={70} />
      </a>
    </div>
  );
}

export default Links;

import React, { useCallback } from "react";
import { HiArrowCircleRight } from "react-icons/hi";
import { HiArrowCircleLeft } from "react-icons/hi";
import "../App.css";
import "../styles/animations.css";
import "../styles/contact_form.css";

function ContactForm() {
  return (
    <div class="container-wrapper" id="Contact">
      <a className="App-link floating card-hover-animation remove" href="#About">
        <HiArrowCircleLeft size={50} />
      </a>
      <div class="contact-wrapper card-hover-animation">
        <form
          action="https://getform.io/f/38f974b5-0d25-4ba8-8f69-6ed07f30adce"
          method="POST"
          target="_blank"
          id="contact_form"
        >
          <div class="name">
            <label for="name"></label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              id="name_input"
              required
            />
          </div>
          <div class="email">
            <label for="email"></label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email_input"
              required
            />
          </div>
          <div class="telephone">
            <label for="name"></label>
            <input
              type="text"
              placeholder="Phone Number"
              name="telephone"
              id="telephone_input"
              required
            />
          </div>
          <div class="telephone">
            <label for="name"></label>
            <input
              type="text"
              placeholder="Subject"
              name="telephone"
              id="telephone_input"
              required
            />
          </div>
          <div class="message">
            <label for="message"></label>
            <textarea
              name="message"
              placeholder="How can I help?"
              id="message_input"
              cols="30"
              rows="5"
              required
            ></textarea>
          </div>
          <div class="submit">
            <input type="submit" value="Send Message" id="form_button" />
          </div>
        </form>
      </div>
      <a className="App-link floating card-hover-animation remove" href="#Links">
        <HiArrowCircleRight size={50} />
      </a>
    </div>
  );
}

export default ContactForm;

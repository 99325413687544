import React, { useCallback } from "react";
import Typical from "react-typical";
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiFillCodepenSquare } from "react-icons/ai";
import { HiArrowCircleRight } from "react-icons/hi";
import "../App.css";
import "../styles/animations.css";
import "../styles/home.css";

function Home() {
  const steps = [
    "",
    3000,
    "I",
    1000,
    "I am",
    1500,
    "I am  .",
    1000,
    "I am  ..",
    1000,
    "I am  ...",
    1000,
    "...🤔",
    3000,
    "A student for life!",
    2000,
    "A student for life!🧑‍🎓",
    3000,
    "A student for life!🧑‍🎓",
    "|42✌️|",
    2000,
    "",
    2000,
    "Unique, ",
    1000,
    "Unique, motivated, ",
    1000,
    "Unique, motivated, curious",
    1000,
    "",
    1000,
    "👽",
    1000,
    "👽🤖",
    1000,
    "👽🤖🤯",
    2000,
    "",
    1000,
    "Focused on Cyber-Security",
    "Focused on Cyber-Security👾🧙",
    3000,
    "Focused on ",
    500,
    "Focused on 👈Front-End/Back-End Dev👉",
    3000,
    "Focused on ",
    1500,
    "Focused on creativity and inovation 🤓",
    3000,
    "And everything in between!",
    2500,
    "",
    1000,
    "So ...",
    1500,
    "So who am I?",
    1000,
    "So who am I??",
    1000,
    "So who am I???",
    2000,
    "",
    1000,
    "👇",
    3500,
    "",
    1000,
  ];
  return (
    <section class="container-wrapper" id="Home">
      <div class="App-header">
        <div class="typer-wrapper">
          <div class="typer">
            <b>
              <Typical loop={Infinity} wrapper="b" steps={steps} />
            </b>
          </div>
        </div>
        <div class="app-footer">
          <a className="App-link floating card-hover-animation remove" href="#About">
            <HiArrowCircleRight size={120} />
          </a>
          <footer className="floating ">
            <a
              className="App-link card-hover-animation"
              href="https://github.com/Dot-kpp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithubSquare size={70} />
            </a>
            <a
              className="App-link card-hover-animation"
              href="https://linkedin.com/in/jeremie-pilotte-791192145"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={70} />
            </a>
            <a
              className="App-link card-hover-animation"
              href="https://codepen.io/dot-kpp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillCodepenSquare size={75} />
            </a>
          </footer>
        </div>
      </div>
    </section>
  );
}

export default Home;
